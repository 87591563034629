import React from 'react'
import { Container, Dropdown, ResponsiveEmbed, Row, Col, Card } from 'react-bootstrap'
import HiprogressCarousel from '../HiprogressCarousel/HiprogressCarousel.component'
import './Policies.styles.scss'

export default function Policies() {
    return (
        <Container className="policies">
            <br/>
            <Row>
                <Col sm={{ span: 6, offset: 3 }}>
                    <HiprogressCarousel />
                </Col>
            </Row>
            <br/>
            <Card>
                <Card.Body>
                    <Card.Title as='h2'>Our Policies:</Card.Title>
                    <Card.Text>
                        <b>Orders processing time:</b><br />
                                The time we need to prepare an order for shipping varies. For details, see individual items.
                            </Card.Text>
                    <Card.Text>
                        <b>Estimated shipping times:</b><br />
                        <Dropdown.Divider />
                        <Row>
                            <Col xs={6}>North America:</Col>
                            <Col xs={6} className="delivery-time">10-20 business days</Col>
                        </Row>
                        <Dropdown.Divider />
                        <Row>
                            <Col xs={6}>Europe:</Col>
                            <Col xs={6} className="delivery-time">10-20 business days</Col>
                        </Row>
                        <Dropdown.Divider />
                        <Row>
                            <Col xs={6}>Australia:</Col>
                            <Col xs={6} className="delivery-time">15-25 business days</Col>
                        </Row>
                        <Dropdown.Divider />
                        <Row>
                            <Col xs={6}>Asia Pacific:</Col>
                            <Col xs={6} className="delivery-time">10-20 business days</Col>
                        </Row>
                        <Dropdown.Divider />
                        <Row>
                            <Col xs={6}>Latin America and the Caribbean:</Col>
                            <Col xs={6} className="delivery-time">15-25 business days</Col>
                        </Row>
                        <Dropdown.Divider />
                    </Card.Text>
                    <Card.Text>
                        <b>Customs and import taxes:</b><br />
                        <i>Buyers are responsible for any customs and import taxes that may apply. I'm not responsible for delays due to customs.</i>
                    </Card.Text>
                    <Row>
                        <Col sm={{span: 4, offset: 1}}>
                            <ResponsiveEmbed aspectRatio="16by9">
                                <iframe title="whiteboard-animation-video" src="https://www.youtube.com/embed/1HqcJNl-5J0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </ResponsiveEmbed>
                        </Col>
                        <Col sm={{ span: 4, offset: 2 }} className="hide-on-med-and-down">
                            <ResponsiveEmbed aspectRatio="16by9">
                                <iframe title="the-one-ronnie" src="https://www.youtube.com/embed/kAG39jKi0lI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </ResponsiveEmbed>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br/>
            <Card>
                <Card.Body>
                    <Card.Title as='h2'>Returns and Exchanges:</Card.Title>
                    <Card.Text>
                        We gladly accept returns and exchanges.<br/>
                        Contact us within: 14 days of delivery.<br />
                        Ship items back within: 30 days of delivery.<br />
                        We don't accept cancellations.
                    </Card.Text>
                    <Card.Text>
                        But please contact us if you have any problems with your order.<br />
                        Because of the nature of these items, unless they arrive damaged or defective, we can't accept returns for custom or personalized orders.
                    </Card.Text>
                    <Card.Text>
                        <b><u>Conditions of return:</u></b><br />
                        Buyers are responsible for return shipping costs.<br />
                        If the item is not returned in its original condition, the buyer is responsible for any loss in value.
                    </Card.Text>
                </Card.Body>
            </Card>
            <br/>
        </Container>
    )
}

import React from 'react'
import { Carousel } from 'react-bootstrap'

export default function HiprogressCarousel() {
    return (
        <Carousel className="hiprogress-carousel">
            {[1, 2, 3, 4, 5, 6, 7].map(num => {
                return (
                    <Carousel.Item key={`carou-${num}`}>
                        <img className="d-block w-100 h-100" src={`/images/HIPROGRESS AD-0${num}.jpg`} alt={`HIPROGRESS AD-0${num}`} style={{ objectFit: 'cover' }} />
                        {/* <Carousel.Caption>
                            <h3>Humming Birds</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption> */}
                    </Carousel.Item>
                )
            })}
        </Carousel>
    )
}

import React from 'react'
import { Container, ResponsiveEmbed, Row, Col, Card } from 'react-bootstrap'
import HiprogressCarousel from '../HiprogressCarousel/HiprogressCarousel.component'

export default function Design() {
    return (
        <Container className="design">
            <br />
            <Row>
                <Col sm={6}>
                    <ResponsiveEmbed aspectRatio="16by9">
                        <iframe title="hiprogress-design-video" src="https://www.youtube.com/embed/7CF_kTbYT1Y" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </ResponsiveEmbed>
                </Col>
                <Col sm={6} className="hide-on-med-and-down">
                    <ResponsiveEmbed aspectRatio="16by9">
                        <iframe title="best-idea-video" src="https://www.youtube.com/embed/jwPc0kK9VHU" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </ResponsiveEmbed>
                </Col>
            </Row>
            <br />
            <Card>
                <Card.Body>
                    <Card.Text style={{fontSize: "14pt"}}>
                        If you have an idea on a new product, HiProgress can join forces in your creation process.
                        We have a simple philosophy, which embraces the idea of continuous improvement.<br /><br />
                        Our single-minded determination to achieve excellence means we set ourselves the highest standards in safety, health and environmental performance, in the efficiency of our operations and in the positive contribution we make to our customers, our people, the industries and communities we serve.<br /><br />
                        We can create and produce a new product in accordance with your sketches, drawings or production file.<br /><br />
                        To join forces please contact us via Etsy Shop - <a href="https://www.etsy.com/shop/HIPROGRESS" target="_blank" rel="noopener noreferrer">https://www.etsy.com/shop/HIPROGRESS</a>
                    </Card.Text>
                </Card.Body>
            </Card>
            <br/>
            <Row>
                <Col sm={{span: 6, offset: 3}}>
                    <HiprogressCarousel />
                </Col>
            </Row>
            <br/>
        </Container>
    )
}

import React from 'react'
import { Carousel } from 'react-bootstrap'
import './MapCarousel.styles.scss'

export default function MapCarousel() {
    return (
        <Carousel indicators={false} className="map-carousel">
            {[9, 8, 1].map(num => {
                return (
                    <Carousel.Item key={`map-carou-${num}`}>
                        <img className="d-block w-100 h-100" src={`/images/export-0${num}.jpg`} alt={`export-0${num}`} style={{ objectFit: 'contain' }} />
                        {/* <Carousel.Caption>
                            <h3>Humming Birds</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption> */}
                    </Carousel.Item>
                )
            })}
        </Carousel>
    )
}

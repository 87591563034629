import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import './ProductsGallery.styles.scss'

export default function ProductsGallery() {
    const imgSources = [
        'https://www.etsy.com/il-en/listing/612443020/clear-acrylic-viking-medieval-battle-axe',
        'https://www.etsy.com/il-en/listing/256877483/universal-plexi-glass-shelf-holder-mount',
        'https://www.etsy.com/il-en/listing/716738216/wall-clear-acrylic-plexi-glass-lucite',
        'https://www.etsy.com/il-en/listing/211663727/triple-wall-holder-stand-display-for',
        'https://www.etsy.com/il-en/listing/699650740/office-table-business-cards-notes-chits',
        'https://www.etsy.com/il-en/listing/838437962/wall-clear-acrylic-plexi-glass-lucite-v',
        'https://www.etsy.com/il-en/listing/457482962/rectangular-square-clear-acrylic-riser',
        'https://www.etsy.com/il-en/listing/506366009/square-clear-4-mm-thk-acrylic-box-with',
        'https://www.etsy.com/il-en/listing/506373639/wall-hanging-square-clear-4-mm-thk'
    ]

    const buildSingleProduct = num => {
        return (
            <Col key={`products-gallery-${num}`} xs={4}>
                <a href={imgSources[num - 1]} target="_blank" rel="noopener noreferrer">
                    <img src={`/images/products-gallery-${num}.jpg`} alt={`products-gallery-${num}`} style={{ objectFit: 'contain', width: "100%" }} />
                </a>
            </Col>
        )
    }

    return (
        <Card className="products-gallery">
            <Card.Body className="products-gallery-body">
                <Card.Title as="h3" className="products-gallery-title">Products Gallery</Card.Title>
                <Row>
                    {[1, 2, 3].map(buildSingleProduct)}
                </Row>
                <br />
                <Row>
                    {[4, 5, 6].map(buildSingleProduct)}
                </Row>
                <br />
                <Row>
                    {[7, 8, 9].map(buildSingleProduct)}
                </Row>
                <br />
                <Card.Text>
                    <Link className="link-to-products" to="/products">Go To Products Page</Link>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

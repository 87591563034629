import React from 'react'
import { Container, ResponsiveEmbed, Row, Col, Card } from 'react-bootstrap'

export default function Contact() {
    return (
        <Container classname="contact">
            <br/>
            <Row>
                <Col sm={{span: 4, offset: 1}}>
                    <ResponsiveEmbed aspectRatio="16by9">
                        <iframe title="customer-service-video" src="https://www.youtube.com/embed/xNxaDllhMyg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </ResponsiveEmbed>
                </Col>
                <Col sm={{ span: 6, offset: 1 }}>
                    <Card>
                        <Card.Body>
                            <Card.Text>
                                <b>Contact Policy:</b><br />
                                We will only use your contact information for:<br />
                                <ul style={{ listStyleType: "circle" }}>
                                    <li>To communicate with you about your question</li>
                                    <li>To fulfill your order</li>
                                </ul>
                                {/* <br /> */}
                                Please contact us via our Etsy Shop:<br/>
                                <a href="https://www.etsy.com/shop/HIPROGRESS" target="_blank" rel="noopener noreferrer">https://www.etsy.com/shop/HIPROGRESS</a>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col sm={{ span: 6, offset: 3 }}>
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26794.807944438766!2d35.2976138133545!3d32.915323647153635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151c33e664765f2b%3A0x1b61c5431d415446!2z15vXqNee15nXkNec!5e0!3m2!1siw!2sil!4v1609361837071!5m2!1siw!2sil" height="100%" width="100%" frameborder="0" style={{ border: "0" }} allowFullScreen aria-hidden="false" tabindex="0"></iframe>
                </Col>
            </Row>
        </Container>
        
    )
}

import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Home from './components/Home/Home.component'
import NavbarWrapper from './components/NavbarWrapper/NavbarWrapper.component'
import Products from './components/Products/Products.component'
import Design from './components/Design/Design.component'
import Policies from './components/Policies/Policies.component'
import Contact from './components/Contact/Contact.component'

import './App.scss';

function App() {
  return (
    <Router>
      <div className="App">
        <Container>
          <header className="site-header">
            <div className="d-flex justify-content-center align-items-center">
              <h1 className="site-title">HiProgress - Original and Creative Design</h1>
            </div>
          </header>
          <NavbarWrapper />
        </Container>
        <main className="main-comp">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/products" component={Products} />
            <Route path="/design" component={Design} />
            <Route path="/policies" component={Policies} />
            <Route path="/contact" component={Contact} />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;

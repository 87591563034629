import React from 'react'
import { Container, ResponsiveEmbed, Row, Col, Card, Dropdown } from 'react-bootstrap'
import ProductsGallery from './ProductsGallery/ProductsGallery.component'
import HiprogressCarousel from '../HiprogressCarousel/HiprogressCarousel.component'
import MapCarousel from '../MapCarousel/MapCarousel.component'
import './Home.styles.scss'

export default function Home() {
    return (
        <Container className="home">
            <br />
            <Row>
                <Col sm={6}>
                    <ResponsiveEmbed aspectRatio="16by9">
                        <iframe title="home-video" src="https://www.youtube.com/embed/TPBeapPO0dY" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </ResponsiveEmbed>
                </Col>
                <Col sm={6}>
                    <br className="hide-on-med-and-up" />
                    <HiprogressCarousel />
                </Col>
                <Col xs={12}><br/></Col>
                <Col sm={8}>
                    <Card>
                        <Card.Body>
                            <Card.Title as='h2'>HiProgress Main Markets:</Card.Title>
                            <Card.Text>
                                The products at this company are distributed very widely to places such as Europe, North America and Oceania. There are a growing interest in the products which is manufactured by HiProgress.<br />
                                Export Percentage: 91% - 100%<br/>
                                Average Shipping Time: 20 Day(s)
                            </Card.Text>
                            <Card.Text>
                                As a reputable company HiProgress has only one primary objective and that is to provide our clients with the most valuable and useful products possible. This is exactly why our customers are very satisfied with our products and this further inspires our company to provide our customers with original solutions. In order to achieve this very important objective we pay very close attention to the needs of our customers and we do everything we can to provide high-quality products which are modern and current. This has resulted in solutions which are exceptionally designed and superbly manufactured.<br/>
                                HiProgress manufacturers various kinds of products such as wall holders for home and office electronics, acrylic stands, acrylic shelves and also acrylic and wooden boxes which are used primarily for home organization.
                                The company is located in Israel and it has been established in 2012.
                                We started to export our products in the same year.
                            </Card.Text>
                            <Card.Text>
                                We always try to be green as possible.<br/>
                                Our main goal in production is Efficient Use of Materials - some products are produced from our scrap material.<br />
                                During our main production processes we collect and sort all scrap materials for future uses.<br />
                                To deal, sort and keep these pieces cost us time and money.<br />
                                Regardless all investments, we try to reduce waste of such materials.<br />
                                Our simple solution, to be green as possible, is to design some small products that can be produced from our scrap materials.​​​<br />
                                You are welcome to join to our exclusive customers around the globe.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br />
                    <Card>
                        <Card.Body>
                            <Card.Title as='h2'>Where our customers from:</Card.Title>
                            <Row>
                                <Col xs={12}>
                                    <MapCarousel />
                                </Col>
                                {/* <Col xs={6}>
                                    <ResponsiveEmbed aspectRatio="16by9">
                                        <iframe title="our-customers-video" src="https://www.youtube.com/embed/Pe00Cp9aJsA?controls=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </ResponsiveEmbed>
                                </Col> */}
                            </Row>
                        </Card.Body>
                    </Card>
                    <br/>
                    <Card>
                        <Card.Body>
                            <Card.Title as='h2'>Our Policies:</Card.Title>
                            <Card.Text>
                                <b>Orders processing time:</b><br />
                                The time we need to prepare an order for shipping varies. For details, see individual items.
                            </Card.Text>
                            <b>Estimated shipping times:</b><br />
                            <Dropdown.Divider />
                            <Row>
                                <Col xs={6}>North America:</Col>
                                <Col xs={6} className="delivery-time">10-20 business days</Col>
                            </Row>
                            <Dropdown.Divider/>
                            <Row>
                                <Col xs={6}>Europe:</Col>
                                <Col xs={6} className="delivery-time">10-20 business days</Col>
                            </Row>
                            <Dropdown.Divider />
                            <Row>
                                <Col xs={6}>Australia:</Col>
                                <Col xs={6} className="delivery-time">15-25 business days</Col>
                            </Row>
                            <Dropdown.Divider />
                            <Row>
                                <Col xs={6}>Asia Pacific:</Col>
                                <Col xs={6} className="delivery-time">10-20 business days</Col>
                            </Row>
                            <Dropdown.Divider />
                            <Row>
                                <Col xs={6}>Latin America and the Caribbean:</Col>
                                <Col xs={6} className="delivery-time">15-25 business days</Col>
                            </Row>
                            <Dropdown.Divider />
                            <Card.Text>
                                <b>Customs and import taxes:</b><br />
                                <i>Buyers are responsible for any customs and import taxes that may apply. I'm not responsible for delays due to customs.</i>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br/>
                </Col>
                <Col sm={4}>
                    <Card>
                        <Card.Body className="about-card">
                            <Card.Title>About HiProgress</Card.Title>
                            <Card.Text>
                                <b>Location:</b> Northern Israel<br />
                                <b>Year Established:</b> 2012<br />
                                <b>Average Production Time:</b><br/>3-5 Business Day(s)
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br/>
                    <Card bg='dark' text='light'>
                        <Card.Body>
                            <Card.Title as='h2' className="shop-card-title">You are welcome to order our products from our shop</Card.Title>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <a href="https://www.etsy.com/shop/HIPROGRESS" style={{ maxWidth: "30%" }} target="_blank" rel="noopener noreferrer">
                                    <img style={{ maxWidth: "100%" }} src="https://mediaprocessor.websimages.com/fit/1920x1920/hiproduct.webs.com/000%20HiProgress%20logo.jpg" alt="logo" />
                                </a>
                            </div>
                        </Card.Body>
                        {/* <Card.Img variant="bottom" src="https://mediaprocessor.websimages.com/fit/1920x1920/hiproduct.webs.com/000%20HiProgress%20logo.jpg" /> */}
                    </Card>
                    <br/>
                    <ProductsGallery />
                </Col>
            </Row>
        </Container>
    )
}

import React from 'react'
import { Carousel, Row } from 'react-bootstrap'
import './ProductsCarousel.styles.scss'

export default function ProductsCarousel(props) {
    const { numImages, category, categoryLink } = props;
    const numScreens = Math.ceil(numImages / 2);
    const oneImageOnLastScreen = numImages % 2 === 1;
    return (
        <Carousel className="products-carousel"> {/*  interval={null} */}
            {[...Array(numScreens).keys()].map(screenNum => {
                console.log(categoryLink);
                return (
                    <Carousel.Item key={`${category}-${screenNum}`}>
                        <a href={categoryLink} target="_blank" rel="noopener noreferrer" >
                            <div className="d-block w-100 h-100">
                                <Row className="add-margin-on-bigger">
                                    <img src={`/images/${category}/products-gallery-${screenNum * 2}.jpg`} alt={`${category}-${screenNum}`} style={{ objectFit: 'contain', width: "100%" }} />
                                </Row>
                                <br />
                                {screenNum === (numScreens - 1) && oneImageOnLastScreen ? null
                                    : <Row className="add-margin-on-bigger">
                                        <img src={`/images/${category}/products-gallery-${screenNum * 2 + 1}.jpg`} alt={`${category}-${screenNum + 1}`} style={{ objectFit: 'contain', width: "100%" }} />
                                    </Row>}
                            </div>
                        </a>
                    </Carousel.Item>
                )
            })}
        </Carousel>
    )
}

import React from 'react'
import { Container, ResponsiveEmbed, Row, Col, Card } from 'react-bootstrap'
import ProductsCarousel from '../ProductsCarousel/ProductsCarousel.component'
import './Products.styles.scss'

export default function Products() {
    return (
        <Container className="products">
            <Card>
                <Card.Body>
                    <Card.Title as='h2'>Our Products</Card.Title>
                    <Row>
                        <Col sm={{ span: 6, offset: 3 }}>
                            <ResponsiveEmbed aspectRatio="16by9">
                                <iframe title="products-video" src="https://www.youtube.com/embed/1sIOWziGRlE" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </ResponsiveEmbed>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Acrylic Holders for Electronic Devices</Card.Title>
                    <ProductsCarousel numImages={13} category="electronic" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=13076913" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Acrylic Shelves</Card.Title>
                    <ProductsCarousel numImages={7} category="shelves" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=18071360" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Shelf Brackets</Card.Title>
                    <ProductsCarousel numImages={2} category="brackets" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=23044993" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Acrylic Holders for the Kitchen</Card.Title>
                    <ProductsCarousel numImages={5} category="kitchen" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=23026716" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Acrylic Holders for the Bathroom</Card.Title>
                    <ProductsCarousel numImages={5} category="bathroom" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=23026712" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Acrylic Boxes</Card.Title>
                    <ProductsCarousel numImages={5} category="boxes" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=13321602" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Acrylic Holders for Weddings</Card.Title>
                    <ProductsCarousel numImages={3} category="wedding" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=23026768" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Acrylic Holders for the Office</Card.Title>
                    <ProductsCarousel numImages={5} category="office" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=23038881" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Acrylic Holders for Wine Bottles</Card.Title>
                    <ProductsCarousel numImages={3} category="wine" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=12532269" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Acrylic Holders for Home Organization</Card.Title>
                    <ProductsCarousel numImages={5} category="organization" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=11479511" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Acrylic Holders for Gym Equipment</Card.Title>
                    <ProductsCarousel numImages={1} category="gym" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=29294564" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Acrylic Handles and Knobs</Card.Title>
                    <ProductsCarousel numImages={1} category="handles" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=15054460" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Acrylic Holders for the Garden</Card.Title>
                    <ProductsCarousel numImages={1} category="garden" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=24057568" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Acrylic Decor Products</Card.Title>
                    <ProductsCarousel numImages={3} category="decor" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=18307934" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>DIY Items and Supplies</Card.Title>
                    <ProductsCarousel numImages={11} category="diy" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=11895595" />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title as='h3'>Wooden Boxes</Card.Title>
                    <ProductsCarousel numImages={3} category="wooden-boxes" categoryLink="https://www.etsy.com/shop/HIPROGRESS?section_id=10459953" />
                </Card.Body>
            </Card>
        </Container>
    )
}

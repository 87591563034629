import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import './NavbarWrapper.styles.scss'


export default function NavbarWrapper() {
    return (
        <Navbar expand="sm" className="navbar-wrapper" bg="dark" variant="dark">
            <Navbar.Brand as={NavLink} to="/">HiProgress Home</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
                <Nav id="actual-navbar">
                    {/* <Nav.Link as={NavLink} to="/">Home</Nav.Link> */}
                    <Nav.Link as={NavLink} to="/products">Products</Nav.Link>
                    <Nav.Link as={NavLink} to="/design">Design</Nav.Link>
                    <Nav.Link as={NavLink} to="/policies">Policies</Nav.Link>
                    <Nav.Link as={NavLink} to="/contact">Contact Us</Nav.Link>
                    {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown> */}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
